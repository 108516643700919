<template>
    <div class="_wrap">
        <div class="_banner">
            <!-- <img src="../../assets/image/takeCare-img/baoguan_bg_detail.png" alt="八路军驻洛办事处纪念馆-官方网站"> -->
            <img :src="$IMG_URL + $store.state.webData.top_image.collection_detail_image" alt="八路军驻洛办事处纪念馆-官方网站">
        </div>


        <div class="_content">
            <div class="_auto">
                <div class="_bread">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: '/takeCare' }">保管</el-breadcrumb-item>
                        <el-breadcrumb-item>藏品征集</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>


                <div class="_nr">

                    <h4>{{ detail.title }}</h4>
                    <p class="_subtit">
                        <span class="_impact">时间：{{ detail.release_date }}</span>
                        <span class="_impact">浏览量：{{ detail.hits }}</span>

                    </p>


                    <div class="_html" v-html="detail.content" ref="myHtml">

                    </div>

                </div>
            </div>
        </div>


        <mainFooter></mainFooter>
    </div>
</template>

<script>
import { collectionCollecting } from "@/api/getData"
export default {
    data() {
        return {
            tdk: {
                title: "详情",
                keywords: "",
                desc: "",
            },
            detail: {},
        }
    },
    // 动态设置页面标题、关键字和描述等属性  
    // metaInfo() {

    //     return {
    //         title: this.tdk.title,
    //         meta: [
    //             { name: "keywords", content: this.tdk.keywords },
    //             { name: "description", content: this.tdk.desc },
    //         ]
    //     }
    // },
    created() {
    },
    async mounted() {

        await this.getData()
        this.$scallHtmlImg()
    },
    methods: {
        async getData() {

            const params = {
                id: this.$route.query.id
            }
            await collectionCollecting(params).then(res => {
                console.log(res)
                this.detail = res.data
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../common/mixin.scss";

._wrap
{
    width: 100%;
    overflow: hidden;
    background-color: #FDFAF6;
}

._banner
{
    width: 100%;

    height: 700px;

    img
    {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }


}

._content
{
    width: 100%;
    margin-bottom: 108px;

    ._auto
    {
        width: 75%;
        margin: 0 auto;

        ._bread
        {
            margin-top: 24px;
        }

        ._nr
        {
            margin-top: 66px;

            h4
            {
                font-size: 30px;
                text-align: center;
            }

            ._subtit
            {
                margin-top: 20px;
                text-align: center;

                span
                {
                    color: #999;
                    font-size: 20px;
                    margin: 0 30px;
                    position: relative;

                    &:nth-child(1)::after
                    {
                        content: '';
                        display: inline-block;
                        width: 1px;
                        height: 16px;
                        background-color: #DFDFDF;
                        position: absolute;
                        right: -30px;
                        top: 5px;
                    }

                }
            }



            ._html
            {
                margin-top: 60px;

                img
                {
                    width: 100%;
                    object-fit: cover;

                }
            }
        }
    }
}

::v-deep .el-breadcrumb__inner
{
    color: #969696 !important;
    font-weight: normal !important;
}

@include respondTo('phone'){
    ._banner{
        height: 180px;
        margin-top: 70px;
    }
    ._content{
        margin-bottom: 20px;
        ._auto{
            padding: 0 8px;
            ._bread{
                margin-top: 20px;
            }
            ._nr{
                margin-top: 20px;
                h4{
                    font-size: 22px;
                }
                ._subtit{
                    margin-top: 10px;
                    span{
                        font-size: 16px;
                        margin: 0 10px;
                    }
                }
            }
        }
    }
}
</style>